<template>
    <div class="machine_info">
        <div class="machine_title">终端选择详情如下: </div>
        <div class="machine_content">
            <div
                v-for="(item, i) in oSelectedModel"
                :key="i"
            >
                <div class="machine_content_model" v-for="(model, key) in item.specs" :key="key">
                    <div v-if="model.select_num">
                        <span>机型 {{ i + 1 }}: </span>
                        <span class="model">{{ `${ item.model_name } ${ item.specs_name } ${ model.mobile_color }` }}</span>
                    </div>
                    <div v-if="model.select_num">
                        x {{ model.select_num }}
                    </div>
                </div>
            </div>
            <div class="machine_content_model info" >
                <div v-for="(io, index) in oTime" :key="index">
                    <span>{{ io.title }}: </span>
                    <span class="model">{{ oInfo[io.key] }}</span>
                </div>
                <div class="textarea">
                    <span>备注: </span>
                    <span>
                        <el-input
                            v-model="remarks"
                            maxlength="100"
                            placeholder="请详细备注机型等信息(非必填)"
                            show-word-limit
                            type="textarea"
                        />
                    </span>
                </div>
            </div>
        </div>
        <button class="my_button _next" @click="evSubmit">提交</button>
    </div>
</template>

<script>
// 接口
import { PostNewInfoMation } from '@/api'

export default {
    data () {
        return {
            // 省份
            l_province: localStorage.getItem('province'),
            // 已选机型
            oSelectedModel: [],
            // 品牌
            oBrandJson: {},
            // 备注
            remarks: '',
            // 信息
            oInfo: {},
            //
            oTime: [
                // { title: '补差款', key: 'payment' },
                { title: '办理套餐', key: 'combo' },
                { title: '期数', key: 'tag' },
                { title: '电子券', key: 'repay_money' }
            ]
        }
    },
    methods: {
        /**
         * 检索已选中机型 (方法)
         *  1. 获取本地数据(关闭程序后, o_m_obj 消失), o_m_obj: 缓存已选择机型数据
         *  2. 判断数据是否存在, 不存在无操作, 存在则执行下列代码
         *  3. 格式化数据, 循环遍历对象, 判断数量是否小于 0
         *      如果不小于 0 , 将筛选出的数据赋值给 oSelectedModel
         */
        fnSearchModel: function () {
            const oMachineHistory = sessionStorage.getItem('o_m_obj')
            if (oMachineHistory) {
                const oM = JSON.parse(oMachineHistory)
                for (const i in oM) {
                    if (oM[i].memory_num > 0) {
                        this.oSelectedModel.push(oM[i])
                    }
                }
            }
        },
        /**
         * 提交
         *  1. 结构赋值, oMobile: 规格 id 数组
         *  2. 遍历已选择机型, 筛选出规格。相同规格是多个时, 重复 push 规格 id
         *  3. 请求 "终端提交" 接口, 赋值(客户 id、期数、补差款、规格 id)
         *      1. 成功: 提示, 返回终端确认页面,删除本地机型、价格缓存
         *      2. 失败: 提示
         */
        evSubmit: function () {
            const { id, tag, payment, repay_money } = this.oInfo
            const { referenceCode } = this.$store.getters.orderItem
            const oMobile = []
            this.oSelectedModel.map((model) => {
                // for (let i = 0; i < model.memory_num; i++) {
                //     oMobile.push({ 'id': model.id })
                // }
                for (let i = 0; i < model.specs.length; i++) {
                    const { select_num, id } = model.specs[i]
                    if (select_num) {
                        oMobile.push({ 'id': model.id, 'specs': id, 'num': select_num })
                        // let arr = [{ 'id': model.id, 'specs': id }]
                        // const division = number => Array.from({ length: number }, () => arr[0])
                        // arr = division(select_num)
                        // oMobile = oMobile.concat(arr)
                    }
                }
            })
            switch (this.l_province) {
                case '1168':
                    /**
                     * 福建
                     * 流程：
                     *  当该号码属于新号码, 且有推荐人，并且 referenceCode 不等于空时.
                     *  有且只能有一台机器，并且只能选择 24 期办理
                     */
                    if (referenceCode) {
                        if (oMobile.length > 1 || oMobile[0].num > 1 || tag !== '24') {
                            this.$message.error('推荐模式下, 只能选择24期且只能选择一款终端!')
                            return
                        }
                    }
                    break
            }
            PostNewInfoMation({
                    repay_money,
                    'white_id': id,
                    'net_age': tag,
                    remarks: this.remarks,
                    'difference_price': payment,
                    'mobile_color': JSON.stringify(oMobile)
                })
                .then(({ code, msg }) => {
                    if (code === 1000) {
                        this.$message.success(msg)
                        this.$router.go(-3)
                        sessionStorage.clear()
                        return
                    }
                    this.$message.error(msg)
                })
        }
        /**
         * 检索已选中机型 (方法) (废弃)
         *  1. 获取本地数据(关闭程序后, machine 消失), machine: 缓存已选择机型数据
         *  2. 判断数据是否存在, 不存在无操作, 存在则执行下列代码
         *  3. 格式化数据, 循环遍历数据, 去除"推荐"数据。因为推荐数据与品牌数据一致。不需要进行重复遍历
         *  4. 抽取所有品牌, 为以后做机型拼接做准备
         *  5. 循环机型, 判断机型内是否有规格
         *  6. 将筛选出的数据赋值给 oSelectedModel
        fnSearchModel1: function () {
            const oMachineHistory = sessionStorage.getItem('machine')
            if (oMachineHistory) {
                const oM = JSON.parse(oMachineHistory)
                for (let i = 0; i < oM.length; i++) {
                    if (oM[i].id !== 0) {
                        this.oBrandJson[oM[i].id] = oM[i].brand_name
                        for (let m = 0, oML = oM[i].model; m < oML.length; m++) {
                            if (oML[m].selected && oML[m].selected.length > 0) {
                                // console.log('[型号]', oML[m].selected)
                                this.oSelectedModel = this.oSelectedModel.concat(oML[m].selected)
                            }
                        }
                    }
                }
            }
        }*/
    },
    mounted () {
        // 父页面传递参数 { payment: 补差款, combo: 套餐, id: 用户id, tag: 期数, repay_money: 电子券 }
        this.oInfo = this.$route.query
        // 检索已选中机型 (方法)
        this.fnSearchModel()
    }
}
</script>

<style lang="scss" scoped>
.machine_info {
    padding: 34px 24px;

    .machine_title {
        font-size: 26px;
        color: #999;

        &::before {
            display: inline-block;
            content: '';
            margin-right: 14px;
            width: 8px;
            height: 26px;
            background: #F2980E;
            border-radius: 0 8px 8px 0;
            overflow: hidden;
            vertical-align: middle;
        }
    }

    // 内容
    .machine_content {
        padding: 24px 20px;
        font-size: 26px;
        line-height: 52px;
        color: #999;
        font-weight: 500;

        .machine_content_model {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &.info {
                div {
                    width: 300px;
                }

                .textarea {
                    width: 100%;
                    .el-textarea {
                        width: 100%;
                    }
                }
            }

            .model {
                color: #333;
                font-weight: 500;
            }
        }
    }

    ._next {
        position: absolute;
        bottom: 54px;
        left: 50%;
        margin-left: -307px;
        letter-spacing: 10px;
        font-weight: 500;
        background: linear-gradient(90deg, #F2970C, #FEC56E);
    }
}
</style>
